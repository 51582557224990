<template>
    <div
        class="container content-width ios"
        :class="{ 'step-en': $i18n.locale === 'en', 'step-ja': $i18n.locale === 'ja' }"
    >
        <div class="block"></div>

        <div class="ts-measure ts-measure-center">
            <h1 class="page-title no-upercase">{{ $t("title") }}</h1>
        </div>

        <div id="HowToInstall" class="anchor"></div>

        <div class="ts-measure-tight">
            <h2>{{ $t("install.title") }}</h2>
            <p class="ts-lead ts-quiet">{{ $t("install.text") }}</p>
            <i18n-link
                :to="{ name: 'set-up-guide-ipad' }"
                class="button button-link button-small"
                hash="HowtoInstall"
                >{{ $t("label.link_ipad") }}</i18n-link
            >
        </div>

        <div class="block-md"></div>

        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">01</h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('install.step01.title')"></h3>
                    <p v-html="$t('install.step01.text')"></p>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-01">
                    <img width="400" :src="imgSrc('01.png')" :alt="$t('install.step01.title')" />
                </div>
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">02</h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('install.step02.title')"></h3>
                    <p v-html="$t('install.step02.text')"></p>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-02">
                    <img width="400" :src="imgSrc('02.png')" :alt="$t('install.step02.title')" />
                </div>
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">03</h3>
                    <img height="34" src="@/assets/images/qr-code-scan.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('install.step03.title')"></h3>
                    <p class="ts-underline ts-bold" v-html="$t('install.step03.note')"></p>
                    <p v-html="$t('install.step03.text1')"></p>
                    <p v-html="$t('install.step03.text2')"></p>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-03">
                    <img width="400" :src="imgSrc('03.png')" :alt="$t('install.step03.title')" />
                </div>
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">04</h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('install.step04.title')"></h3>
                    <p v-html="$t('install.step04.text1')"></p>
                    <p v-html="$t('install.step04.text2')"></p>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-04">
                    <img width="400" :src="imgSrc('04.png')" :alt="$t('install.step04.title')" />
                </div>
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number" v-html="$t('install.step05.tag')"></h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('install.step05.title')"></h3>
                    <p v-html="$t('install.step05.text')"></p>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-05">
                    <img width="400" :src="imgSrc('05.png')" :alt="$t('install.step05.title')" />
                </div>
            </div>
        </div>

        <div id="HowToUse" class="anchor"></div>

        <div class="ts-measure-tight">
            <h2 v-html="$t('use.title')"></h2>
            <p class="ts-lead ts-quiet" v-html="$t('use.text')"></p>
            <i18n-link
                :to="{ name: 'set-up-guide-ipad', hash: '#HowToUse' }"
                class="button button-link button-small"
                hash="HowtoInstall"
                >{{ $t("label.link_ipad") }}</i18n-link
            >
        </div>

        <div class="block-md"></div>

        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">01</h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('use.step01.title')"></h3>
                    <p v-html="$t('use.step01.text')"></p>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-09">
                    <img width="400" :src="imgSrc('09.png')" :alt="$t('use.step01.title')" />
                </div>
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">02</h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('use.step02.title')"></h3>
                    <p v-html="$t('use.step02.text')"></p>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-10">
                    <img width="400" :src="imgSrc('10.png')" :alt="$t('use.step02.title')" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.set_up_ios.title"),
            description: this.$i18n.t("meta.set_up_ios.description"),
        })
    },

    data() {
        return {
            imageToggle: "balance",

            openImage: false,
        }
    },
    methods: {
        imgSrc(fileName) {
            const locale = this.$i18n.locale
            return require(`@/assets/images/screenshot/set-up-ios/${locale}/${fileName}`)
        },
    },
    i18n: {
        messages: {
            en: {
                title: "SET UP GUIDE FOR iOS",
                install: {
                    title: "How To Install Stork Mobile eSIM",
                    text:
                        "For iPhone running iOS 16. The actual text or screen display may vary depending on the iOS version and the phone model.",
                    step01: {
                        title: "Navigate to Cellular > Add eSIM",
                        text: "It might say Add Cellular Plan on older iOS versions.",
                    },
                    step02: {
                        title: "Tap “Use QR Code”",
                        text:
                            "Please select this option even if you're manually installing without using a QR code.",
                    },
                    step03: {
                        title: "Scan QR Code",
                        note: "* Internet connection required",
                        text1:
                            "If you are installing eSIM manually, tap “Enter Details Manually” at the bottom of the screen.",
                        text2:
                            "The necessary codes for the manual installation will be provided along with QR code.",
                    },
                    step04: {
                        title: "Activate eSIM",
                        text1:
                            "Tap “Continue” to complete the activation. (At this point, you cannot cancel or reuse the QR code.)",
                        text2:
                            "If Cellular Setup Complete appears on the screen, the eSIM has been installed successful.",
                    },
                    step05: {
                        tag: "Optional",
                        title: "Update Cellular Plan Label",
                        text:
                            "For easy identification, label the installed eSIM / Cellular Plan as “Stork Mobile” under the CUSTOM LABEL section. (It will be labeled as “Cellular Data” by default.)",
                    },
                },
                use: {
                    title: "How To Use Stork Mobile eSIM For Mobile Data",
                    text: "Follow these steps after you arrive at your travel destination.",
                    step01: {
                        title: "Turn on Stork Mobile eSIM (Cellular Plan)",
                        text:
                            "Go to <span class='ts-italic'>Settings</span> > <span class='ts-italic'>Cellular or Mobile Data</span> and make sure Stork Mobile eSIM is turned on.",
                    },
                    step02: {
                        title: "Set it for Cellular Data",
                        text: "Select Stork Mobile eSIM for Cellular Data.",
                    },
                },
                amp: {
                    next: {
                        title: "about stork mobile",
                    },
                },
            },
            ja: {
                title: "SET UP GUIDE FOR iOS",
                install: {
                    title: "eSIM のインストール方法",
                    text:
                        "ここではiPhone (iOS 16) での設定方法を解説します。iOSのバージョンや機種によって使われるテキストやインターフェイスが多少異なる場合があります。",
                    step01: {
                        title: "設定 > モバイル通信 > eSIMを追加 を選択",
                        text:
                            "iOSのバージョンによっては「モバイル通信プランを追加」となっている場合があります。",
                    },
                    step02: {
                        title: "「QRコードを使用」を選択",
                        text:
                            "QRコードを使わず手動でインストールする場合も、こちらを選択してください。",
                    },
                    step03: {
                        title: "QRコードを読み取る",
                        note: "* インターネットが必要です",
                        text1:
                            "QRコードを表示する端末がない場合は、「詳細情報を手動で入力」をタップし、SM-DP+ アドレスとアクティベーションコードを入力してください。",
                        text2: "これらの情報はQRコードと合わせてメールでお送りします。",
                    },
                    step04: {
                        title: "eSIMをアクティベート",
                        text1:
                            "「続ける」をタップしてください。（*タップ後はキャンセルやQRコードの再利用はできません。）",
                        text2:
                            "「モバイル通信設定完了」と表示されれば、インストールおよびアクティベート完了です。",
                    },
                    step05: {
                        tag: "任意",
                        title: "モバイル通信プランの名称を変更",
                        text:
                            "インストールしたeSIMの「モバイル通信プランの名称」から、カスタム名称として Stork Mobile などにわかりやすい名称に変更しましょう。（デフォルト名称は「モバイルデータ通信」等になっています。）",
                    },
                },
                use: {
                    title: "eSIMをデータ通信に使う",
                    text: "旅行先に到着したら、次の手順にしたがって設定してください。",
                    step01: {
                        title: "Stork Mobile eSIM を有効にする",
                        text:
                            "設定アプリから「モバイル通信」をタップして、 Stork Mobile eSIM を有効にしてください。",
                    },
                    step02: {
                        title: "モバイルデータ通信に設定",
                        text: "「モバイルデータ通信」にStork Mobileの回線を設定してください。",
                    },
                },
                amp: {
                    next: {
                        title: "stork mobileについて",
                    },
                },
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.image-wrapper {
    width: 415px;
    max-height: 300px;

    @media (max-width: 415px) {
        width: 100%;
    }
    overflow: hidden;
    position: relative;
    &:after {
        content: " ";
        width: 413px;
        height: 298px;
        background: linear-gradient(rgba(255, 255, 255, 0.5), #fff);
        position: absolute;
        top: 1px;
        left: 1px;
    }
    button {
        z-index: 9999;
        position: absolute;
        top: 150px;
        left: 30%;
        right: 30%;
        @media (max-width: 400px) {
            left: 28%;
            right: 28%;
        }
    }
    &.open {
        max-height: none;
        overflow: auto;
        &:after {
            display: none;
        }
        button {
            display: none;
        }
    }
}
</style>
